import React, { Component } from "react"
import SEO from "../components/seo"

import Typed from 'react-typed'
import posed from 'react-pose'
import ParallaxMousemove from "react-parallax-mousemove"
import Menu from "../components/menu"

import CharacterSVG from "../images/intro-character.svg"
import GalaxySVG from "../images/galaxy.svg"
import PlanetsSVG from "../images/planets.svg"
import WindowSVG from "../images/window.svg"

const ChatBox = posed.div({
  off: {
    x: -10,
    y: 100,
    opacity: 0,
    scale: 0,
    transition: { duration: 300 },
  },
  on: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { duration: 700 },
  },
})

class IndexPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showChat: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showChat: true
      })
    }, 200)
  }

  render() {
    return (
      <>
        <SEO title="Home" />
        <div className="intro">
          <div className="intro-text">
            <ChatBox
              className="bubble bubble-alt white"
              pose={this.state.showChat ? "on" : "off"}
            >
              Hello!
              <span
                role="img"
                aria-label="jsx-a11y/accessible-emoji"
                className="hand-emoji"
              >
                🖐
              </span>
              <br></br>I am <span>Frederic Ronaldi</span>,<br></br>
              {/* <svg viewBox="0 0 650 90">
                <symbol id="s-text">
                  <text text-anchor="middle" x="50%" y="50%" dy=".35em">
                    Frederic Ronaldi
                  </text>
                </symbol>
                <use class="text" xlinkHref="#s-text"></use>
                <use class="text" xlinkHref="#s-text"></use>
                <use class="text" xlinkHref="#s-text"></use>
                <use class="text" xlinkHref="#s-text"></use>
                <use class="text" xlinkHref="#s-text"></use>
              </svg> */}
              A{" "}
              <Typed
                strings={[
                  "Software Engineer",
                  "Tech Geek",
                  "Lifetime learner",
                  "Gamer",
                ]}
                typeSpeed={10}
                loop
              />
              <br></br>
              based in Bandung, Indonesia
            </ChatBox>
          </div>
          <div className="intro-image">
            <div className="intro-character">
              <img src={CharacterSVG} alt="Intro" />
            </div>
              <ParallaxMousemove>
                <ParallaxMousemove.Layer
                  layerStyle={{
                    position: "absolute",
                  }}
                  config={{
                    xFactor: 0.05,
                    yFactor: 0.1,
                  }}
                >
                  <img
                    src={GalaxySVG}
                    className="shape"
                    alt="Galaxy background"
                  />
                </ParallaxMousemove.Layer>
                <ParallaxMousemove.Layer
                  layerStyle={{
                    position: "absolute",
                  }}
                  config={{
                    xFactor: -0.02,
                    yFactor: 0.04,
                  }}
                >
                  <img src={PlanetsSVG} className="planets" alt="Planets" />
                </ParallaxMousemove.Layer>
                <ParallaxMousemove.Layer
                  layerStyle={{
                    position: "absolute",
                  }}
                  config={{
                    xFactor: 0.08,
                    yFactor: 0.12,
                  }}
                >
                  <img
                    src={WindowSVG}
                    className="window"
                    alt="Programming windows"
                  />
                </ParallaxMousemove.Layer>
              </ParallaxMousemove>
          </div>
        </div>
        <Menu active="home" />
      </>
    )
  }
}

export default IndexPage
